import React from "react"
import { CacheProvider } from "@emotion/react"
import createEmotionCache from "./src/createEmotionCache"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "./src/theme"

const cache = createEmotionCache()

export const wrapRootElement = ({ element }) => (
  <CacheProvider value={cache}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  </CacheProvider>
)

export const onClientEntry = () => {
  const link1 = document.createElement("link")
  link1.rel = "preconnect"
  link1.href = "https://fonts.googleapis.com"
  document.head.appendChild(link1)

  const link2 = document.createElement("link")
  link2.rel = "preconnect"
  link2.href = "https://fonts.gstatic.com"
  link2.crossOrigin = "anonymous"
  document.head.appendChild(link2)

  const link3 = document.createElement("link")
  link3.rel = "stylesheet"
  link3.href =
    "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap"
  link3.crossOrigin = "anonymous"
  document.head.appendChild(link3)

  const link4 = document.createElement("link")
  link4.rel = "stylesheet"
  link4.href =
    "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Jost:ital,wght@0,100..900;1,100..900&display=swap"
  link4.crossOrigin = "anonymous"
  document.head.appendChild(link4)
}
